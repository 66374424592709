import { getComments } from "../comments/get-coments.js";
import { commentsWrapper, downloadZip, setCurrentTape, shareEmail, shareFacebook, shareTwitter, tapeContentEdit } from "../global-var.js";
import { createBtn, createEl, createElWithHTML, createFragment, createImageEl } from "../global/global-dom.js";
import { formatDate } from "../global/global-strings.js";
import { historyHandler } from "../spa/history.js";
import { addTapeListeners } from "./tape-listeners.js";
export function executeTape(tape, state) {
    document.documentElement.scrollTop = 0;
    const artistH = createElWithHTML('h1', 'artist-h1', tape.t_artist);
    const albumH = createElWithHTML('h2', 'album-h2', tape.t_album);
    const playBtn = createBtn('btns ease-out', { type: 'button', id: 'playAlbumBtn', title: 'Play Album', text: 'Play Album', 'data-id': tape.t_id.toString(), 'data-track': '0' });
    const albumCover = createImageEl('album-cover', { src: tape.t_assets.img_src, alt: `${tape.t_artist.toUpperCase()} - ${tape.t_album}` });
    const fragments = [artistH, albumH, playBtn, albumCover,
        createTapeDesc(tape.t_date, tape.t_desc),
        createTapeSides(tape.t_assets.sideA, tape.t_id, 'SIDE A', 0),
        createTapeSides(tape.t_assets.sideB, tape.t_id, 'SIDE B', tape.t_assets.sideB.length),
        createTags(tape.t_label, tape.t_tag, tape.t_genre)];
    const fragment = createFragment(fragments);
    tapeContentEdit.innerHTML = '';
    commentsWrapper.innerHTML = '';
    tapeContentEdit.append(fragment);
    setCurrentTape(tape.t_id);
    updateShareDl(tape);
    getComments(tape.t_id);
    addTapeListeners();
    if (!state)
        historyHandler(tape.t_url, false);
    tapeContentEdit.setAttribute('data-url', tape.t_url);
}
function createTapeDesc(date, desc) {
    const div = createEl('div', 'tape-content-wrapper');
    const dateEl = createElWithHTML('h3', '', formatDate(date));
    const descEl = createElWithHTML('p', 'tape-content-read', desc);
    div.append(dateEl, descEl);
    return div;
}
function createTapeSides(side, tapeId, sideTitle, count) {
    const div = createEl('div', 'tape-content-wrapper');
    const sideEl = createElWithHTML('p', 'tape-content-titles', sideTitle);
    div.append(sideEl);
    side.forEach(tr => {
        div.append(createElWithHTML('p', 'tracks links ease-out', `${tr.title}<span>${createPlayBtn()}</span>`, { 'data-track': count, 'data-id': tapeId }));
        count++;
    });
    return div;
}
function createPlayBtn() {
    return `
        <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" class="tracks-play-svg">
            <title>Play</title>
            <path fill="#000"
                d="m6.38002,0.475025l0,59.049949l47.239959,-29.524975l-47.239959,-29.524975l0,0z" />
            </svg>`;
}
function createTags(label, tags, genres) {
    const div = createEl('div', 'tape-content-wrapper');
    const labelTitle = createElWithHTML('p', 'tape-content-titles', 'LABEL');
    const labelEl = createElWithHTML('p', 'links label', label, { 'data-tag': label });
    const tagTitle = createElWithHTML('p', 'tape-content-titles', 'TAGS');
    div.append(labelTitle, labelEl, tagTitle);
    const allTags = genres.concat(tags);
    allTags.forEach(tag => {
        div.append(createElWithHTML('button', 'tags ease-out', tag, { type: 'button', 'data-tag': tag }));
    });
    return div;
}
function updateShareDl(tape) {
    downloadZip.setAttribute('data-url', tape.t_assets.zip_src);
    shareFacebook.setAttribute('data-url', tape.t_url);
    shareTwitter.setAttribute('data-url', tape.t_url);
    shareEmail.setAttribute('data-url', tape.t_url);
    shareEmail.setAttribute('data-artist', tape.t_artist);
    shareEmail.setAttribute('data-album', tape.t_album);
}
