import { commentForm, commentMsg, commentName, commentSubmitBtn, commentsWrapper, tapeContent } from "../global-var.js";
import { $, createBtnWithSpinner, createElWithHTML, _ } from "../global/global-dom.js";
import { fetchAPI } from "../global/global-fetch.js";
import { createForm, createInput, createTextArea, resetForm } from "../global/global-forms.js";
import { onRequest, onResponse } from "../global/global-handlers.js";
import { appendComments } from "./comments-dom.js";
export function addCommentListeners() {
    const replyBtns = $(commentsWrapper, '.reply-comment-btn');
    replyBtns.forEach(btn => btn.addEventListener('click', onShowReplyForm));
}
function onShowReplyForm() {
    const replyBtns = $(commentsWrapper, '.reply-comment-btn');
    replyBtns.forEach(btn => btn.classList.remove('no-display'));
    const replyForms = $(commentsWrapper, '.reply-form');
    replyForms.forEach(form => form.remove());
    const tapeID = commentForm.getAttribute('data-tape');
    this.after(createReplyForm(tapeID));
    this.classList.add('no-display');
    const replyForm = $(commentsWrapper, '.reply-form')[0];
    replyForm.addEventListener('submit', onCommentSubmit);
}
function createReplyForm(tapeID) {
    const form = createForm({ id: 'replyForm', 'data-tape': tapeID.toString() }, 'comment-form reply-form flex-container flex-col');
    const input = createInput({ type: 'text', placeholder: 'Your Name', required: 'required', id: 'replyName' });
    const textArea = createTextArea({ placeholder: 'Reply to comment...', required: 'required', id: 'replyMsg', oninput: `this.style.height = ''; this.style.height = this.scrollHeight + 'px';` });
    const button = createBtnWithSpinner('btns reply-submit-btn ease-out', { type: 'submit', title: 'REPLY', text: 'REPLY', id: 'replySubmitBtn' });
    form.append(input, textArea, button);
    return form;
}
//FORM SUBMITS
export function onCommentSubmit(e) {
    e.preventDefault();
    const target = e.currentTarget;
    const onReply = (target.id === 'replyForm') ? true : false;
    const btn = (onReply) ? _('replySubmitBtn') : commentSubmitBtn;
    onRequest(btn);
    const name = (onReply) ? _('replyName').value.trim() : commentName.value.trim();
    const comment = (onReply) ? _('replyMsg').value.trim() : commentMsg.value.trim();
    const tape = Number(target.getAttribute('data-tape'));
    const parentId = (onReply) ? Number(target.parentElement?.getAttribute('data-id')) : null;
    submitComment({ name, comment, tape, parentId }, btn);
}
async function submitComment(payload, btn) {
    const resp = await fetchAPI('/add-comment', 'POST', payload);
    onResponse(btn);
    const errorEls = $(tapeContent, '.contact-result');
    errorEls.forEach(er => er.remove());
    if (resp && resp.error) {
        appendErrorResult(btn, resp.error);
        return;
    }
    if (resp.insertedComment.c_parentId === null)
        resetForm(commentForm); //ON COMMENT, NOT REPLY
    appendComments(resp.comments, resp.insertedComment.c_tape);
}
function appendErrorResult(btn, error) {
    const p = createElWithHTML('p', 'contact-result error', error);
    const div = (btn.getAttribute('id') === 'replySubmitBtn') ? $(commentsWrapper, '.reply-form')[0] : commentForm;
    resetForm(div);
    div.append(p);
}
