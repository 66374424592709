import { commentCount, commentForm, commentsWrapper } from "../global-var.js";
import { createBtn, createEl, createElWithHTML, createElWithText, createFragment } from "../global/global-dom.js";
import { resetForm } from "../global/global-forms.js";
import { addCommentListeners } from "./comments-listeners.js";
export function appendComments(comments, tapeID) {
    const fragments = [];
    const parentComments = comments.filter(c => c.c_parentId === null);
    parentComments.forEach(comment => {
        const div = createEl('div', 'comment', { 'data-id': comment.c_id });
        const commentEls = createComment(comment, false);
        commentEls.forEach(e => div.append(e));
        const replies = comments.filter(reply => reply.c_parentId === comment.c_id);
        replies.forEach(reply => {
            const replyDiv = createEl('div', 'reply');
            const replyEls = createComment(reply, true);
            replyEls.forEach(e => replyDiv.append(e));
            div.append(replyDiv);
        });
        fragments.push(div);
    });
    const fragment = createFragment(fragments);
    commentsWrapper.innerHTML = '';
    commentsWrapper.append(fragment);
    updateCommentCount(comments.length);
    addCommentListeners();
    commentForm.setAttribute('data-tape', tapeID.toString());
    resetForm(commentForm);
}
function updateCommentCount(count) {
    commentCount.setAttribute('data-count', count.toString());
    commentCount.innerText = `(${count})`;
}
export function removeCommentCount() {
    commentCount.removeAttribute('data-count');
    commentCount.innerText = '';
}
function createComment(comment, onReply) {
    const header = createElWithText('h1', '', comment.c_user);
    const date = createElWithHTML('h2', '', `${comment.c_date}<span class="time-zone">(EST)</span>`);
    const text = createElWithHTML('p', 'tape-content-read', comment.c_comment.replace(/\n/ig, '<br>'));
    if (onReply)
        return [header, date, text];
    const btn = createBtn('btns reply-comment-btn ease-out', { type: 'button', text: 'REPLY', title: 'REPLY' });
    return [header, date, text, btn];
}
