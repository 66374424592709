import { thumbsWrapper } from "../global-var.js";
import { $, createEl, createFragment } from "../global/global-dom.js";
import { onThumbClick } from "./thumb-listeners.js";
export function updateThumbs(data) {
    const fragments = [];
    data.forEach(row => {
        const div = createEl('div', 'thumb textOverImage bckg bckg-right no-scroll ease-out', { 'data-title': row.t_artist, 'data-album': row.t_album, 'data-id': row.t_id, style: `background-image: url(${row.t_assets.img_src});` });
        fragments.push(div);
    });
    const fragment = createFragment(fragments);
    thumbsWrapper.innerHTML = '';
    thumbsWrapper.append(fragment);
    const thumbs = $(thumbsWrapper, '.thumb');
    thumbs.forEach(th => th.addEventListener('click', onThumbClick));
}
