import { audioPlayerWrapper, header, searchResults, sideBarRight } from "../global-var.js";
import { rootDoc } from "../global/global-dom.js";
import { openDrawer } from "./side-bars.js";
export function onResize() {
    const vh = window.innerHeight / 100;
    rootDoc.style.setProperty("--vh", vh + "px");
    if (window.innerWidth > 1080) {
        openDrawer(header, 'transform-sbleft');
        openDrawer(sideBarRight, 'transform-sbright');
        audioPlayerWrapper.classList.remove('audio-player-change');
        searchResults.classList.remove('search-sb-change');
    }
}
