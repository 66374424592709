import { APP_VERSION, refreshOverlay, reloadBtn } from "../global-var.js";
import { rootDoc } from "../global/global-dom.js";
import { fetchAPI } from "../global/global-fetch.js";
export function startRefreshTimeout() {
    setInterval(() => { fetchAppVersion(); }, 86400000); //FETCH APP VERSION AFTER A DAY OF STALE STATE
}
async function fetchAppVersion() {
    const payload = { version: APP_VERSION };
    const resp = await fetchAPI('/app-version', 'POST', payload);
    console.log(resp);
    if (resp.error || (resp.version && resp.version === APP_VERSION))
        return;
    showRefreshOverlay();
}
function showRefreshOverlay() {
    rootDoc.classList.add('no-scroll');
    refreshOverlay.classList.remove('no-display');
    setTimeout(() => { refreshOverlay.classList.add('overlay-change'); }, 100);
    reloadBtn.addEventListener('click', forceRefresh);
}
function forceRefresh() {
    location.reload();
}
