import { $, createEl } from "./global-dom.js";
import { findReplace } from "./global-strings.js";
//FORM
export function createForm(attr, classes) {
    const form = createEl('form', classes);
    for (let key in attr) {
        form.setAttribute(key, attr[key]);
    }
    return form;
}
//INPUT
export function createInput(attr, val) {
    const input = createEl('input', 'input-fields');
    for (let key in attr) {
        input.setAttribute(key, attr[key]);
    }
    if (val && attr.type != 'file') {
        input.value = val;
    }
    return input;
}
//TEXTAREA
export function createTextArea(attr, val) {
    const wrapper = createEl('div', 'grow-wrap');
    const textArea = createEl('textarea', 'input-fields');
    for (let key in attr) {
        textArea.setAttribute(key, attr[key]);
    }
    textArea.setAttribute('oninput', `this.parentNode.dataset.replicatedValue = this.value`);
    if (val) {
        textArea.value = findReplace(val, '<br>', '\n');
    }
    wrapper.append(textArea);
    return wrapper;
}
//CUSTOM FUNCTIONS
export function resetForm(form) {
    form.reset();
    const textAreas = $(form, 'textarea');
    textAreas.forEach(t => {
        const growWrap = t.parentElement;
        if (!growWrap)
            return;
        growWrap.removeAttribute('data-replicated-value');
    });
}
