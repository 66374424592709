export function mapProp(data, prop) {
    return data.map(item => { return item[prop]; });
}
export function mapObjProp(data, obj, prop) {
    return data.map(item => { return item[obj][prop]; });
}
export const sortByProp = (data, prop) => {
    return data.map(a => a).sort((a, b) => String(a[prop]).localeCompare(String(b[prop])));
};
export function sortByObjProp(data, obj, prop) {
    return data.map(a => a).sort((a, b) => String(a[obj][prop]).localeCompare(String(b[obj][prop])));
}
export function getRecordByPropVal(data, prop, val) {
    return data.find(rec => String(rec[prop]) === val);
}
export function filterRecordsBySearch(data, search) {
    return data.filter(row => String(row).toLowerCase().includes(search));
}
export function filterRecordsByPropAndSearch(data, prop, search) {
    return data.filter(row => String(row[prop]).toLowerCase().includes(search));
}
export function sortByDescendingNumber(data, prop) {
    return data.map(a => a).sort((a, b) => Number(b[prop]) - Number(a[prop]));
}
export function removeKeys(data, keys) {
    keys.forEach(key => { delete data[key]; });
    return data;
}
