import { audioPlayerWrapper, playerMax, playerMaxAlbum, playerMaxArtist, playerMin } from "../global-var.js";
import { $ } from "../global/global-dom.js";
import { onPlayerMin } from "./audio-dom.js";
import { IS_PLAYER_MAX } from "./audio.js";
function marqueeHandler(elText, elSpan) {
    const style = window.getComputedStyle(elText);
    const padding = parseFloat(style.paddingLeft) * 2;
    const computedWidth = (elText.offsetWidth - padding) * .95; //95% due to marquee fade
    if (elSpan.offsetWidth >= computedWidth) {
        elText.classList.add('marquee-change');
    }
    else {
        elText.classList.remove('marquee-change');
    }
}
export function onAudioResize() {
    const el = (IS_PLAYER_MAX) ? playerMax : playerMin;
    const title = $(el, '.player-title')[0];
    const span = $(title, 'span')[0];
    marqueeHandler(title, span);
    if (IS_PLAYER_MAX) {
        const album = $(playerMax, '.player-album')[0];
        const artist = $(el, '.player-artist')[0];
        const tracks = $(el, '.player-track');
        tracks.forEach(track => {
            const trackSpan = $(track, 'span')[0];
            marqueeHandler(track, trackSpan);
        });
        marqueeHandler(album, playerMaxAlbum);
        marqueeHandler(artist, playerMaxArtist);
    }
}
export function handleAudioPlayer() {
    if (!audioPlayerWrapper.classList.contains('no-display') && audioPlayerWrapper.classList.contains('audio-player-max')) {
        onPlayerMin();
    }
}
