import { audioPlayerWrapper, getTapeById } from "../global-var.js";
import { mapProp } from "../global/global-data-manip.js";
import { updateAlbumTitles, updateAudioThumbs, updateFullTrackListView, updateTitles } from "./audio-dom.js";
import { addAudioListeners, onPause, onPlay } from "./audio-listeners.js";
import { onAudioResize } from "./audio-resize.js";
//GLOBAL AUDIO VARIABLES
export let AUDIO_ELEMENT, CURRENT_AUDIO_TAPE = 0, DUR_LIST = [], TRACK_LIST = [], SRC_LIST = [], CURRENT_SONG = 0, TRACKS_LENGTH = 0, ALBUM_TITLE = '', ARTIST_TITLE = '', IS_AUDIO_ON = false, IS_PLAYER_MAX = false, IS_LOADING = false, VOLUME_GLOBAL = 1;
//GLOBAL SETTERS
export function setIsLoading(val) {
    IS_LOADING = val;
}
export function setCurrentSong(num) {
    CURRENT_SONG = num;
}
export function setIsAudioOn(val) {
    IS_AUDIO_ON = val;
}
export function setVolumeGlobal(num) {
    VOLUME_GLOBAL = num;
}
export function setIsPlayerMax(val) {
    IS_PLAYER_MAX = val;
}
export function onTrackClick() {
    if (audioPlayerWrapper.classList.contains('transform-y-100') && audioPlayerWrapper.classList.contains('no-display')) { //FIRST TIME AUDIO
        audioPlayerWrapper.classList.remove('no-display');
        AUDIO_ELEMENT = new Audio();
        setTimeout(() => { audioPlayerWrapper.classList.remove('transform-y-100'); }, 10);
        addAudioListeners();
    }
    const tapeID = Number(this.getAttribute('data-id'));
    if (tapeID === CURRENT_AUDIO_TAPE) { //SAME TAPE
        CURRENT_SONG = Number(this.getAttribute('data-track'));
        onPause();
        AUDIO_ELEMENT.src = SRC_LIST[CURRENT_SONG];
        onPlay();
        updateTitles();
        onAudioResize();
    }
    else { //NEW TAPE
        const id = Number(this.getAttribute('data-id'));
        CURRENT_SONG = Number(this.getAttribute('data-track'));
        const tape = getTapeById(tapeID);
        if (!tape)
            return;
        initAudio(tape);
    }
}
function initAudio(data) {
    SRC_LIST.length = 0;
    TRACK_LIST.length = 0;
    DUR_LIST.length = 0;
    CURRENT_AUDIO_TAPE = data.t_id;
    const durArr = [...mapProp(data.t_assets.sideA, 'dur'), ...mapProp(data.t_assets.sideB, 'dur')]; //avoid rewriting on original object
    const srcArr = [...mapProp(data.t_assets.sideA, 'src'), ...mapProp(data.t_assets.sideB, 'src')]; //avoid rewriting on original object
    const trackArr = [...mapProp(data.t_assets.sideA, 'title'), ...mapProp(data.t_assets.sideB, 'title')]; //avoid rewriting on original object
    DUR_LIST = durArr;
    SRC_LIST = srcArr;
    TRACK_LIST = trackArr;
    TRACKS_LENGTH = TRACK_LIST.length;
    AUDIO_ELEMENT.src = SRC_LIST[CURRENT_SONG];
    ALBUM_TITLE = data.t_album;
    ARTIST_TITLE = data.t_artist;
    onPlay();
    updateAlbumTitles();
    updateFullTrackListView();
    updateTitles();
    updateAudioThumbs(data.t_assets.img_src);
    onAudioResize();
}
