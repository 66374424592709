import { commentsWrapper, CURRENT_TAPE } from "../global-var.js";
import { createCommentsLoader } from "../global/global-dom.js";
import { fetchAPI } from "../global/global-fetch.js";
import { appendComments, removeCommentCount } from "./comments-dom.js";
export async function getComments(tapeID) {
    commentsWrapper.append(createCommentsLoader());
    removeCommentCount();
    const resp = await fetchAPI('/get-comments', 'POST', { tapeID: tapeID });
    if (!resp || resp && resp.error) {
        console.error(resp.error);
        return;
    }
    if (CURRENT_TAPE != resp.tapeID)
        return;
    appendComments(resp.comments, resp.tapeID);
}
