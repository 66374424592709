import { addCommentListeners, onCommentSubmit } from "./comments/comments-listeners.js";
import { startRefreshTimeout } from "./dom-states/refresh.js";
import { onResize } from "./dom-states/resize.js";
import { onSBLeftClick, onSBRightClick } from "./dom-states/side-bars.js";
import { onContactSubmit } from "./forms/contact.js";
import { onSubscribeSubmit, resetSubscribeForm, showSubscribeOverlay } from "./forms/subscribe.js";
import { albumCaption, audioPlayerWrapper, closeSubOverlay, commentForm, contactForm, errorOverlay, fixedContainer, genreDropDown, headerDrawer, initDBVariables, navLinks, overlays, pages, refreshOverlay, sbRightDrawer, searchInput, searchResults, setIsTouchDevice, subscribeBtn, subscribeForm, subscribeOverlay } from "./global-var.js";
import { rootDoc } from "./global/global-dom.js";
import { onDropDown } from "./search/filter.js";
import { onSearchInput } from "./search/search.js";
import { onPopState } from "./spa/history.js";
import { routeHandler } from "./spa/router.js";
import { onALinks } from "./spa/spa.js";
import { addDownloadAndShareListeners, addTapeListeners } from "./tapes/tape-listeners.js";
import { addThumbListeners } from "./tapes/thumb-listeners.js";
onResize();
document.addEventListener("DOMContentLoaded", onDOMLoaded);
window.addEventListener('resize', onResize);
window.addEventListener('popstate', onPopState);
function onDOMLoaded() {
    if (window.innerWidth <= 1340 && window.innerWidth > 1080) {
        searchResults.classList.add('search-sb-change');
    }
    setIsTouchDevice('ontouchstart' in document.documentElement);
    const isInitialized = initDBVariables();
    if (!isInitialized) {
        showErrorOverlay();
        return;
    }
    routeHandler();
    addGlobalListeners();
    startRefreshTimeout();
}
function addGlobalListeners() {
    //OVERLAYS
    overlays.forEach(e => e.addEventListener('click', onOverlayClick));
    //NAV
    navLinks.forEach(a => a.addEventListener('click', onALinks));
    //FORM SUBMITS
    searchInput.addEventListener('keyup', onSearchInput);
    genreDropDown.addEventListener('change', onDropDown);
    commentForm.addEventListener('submit', onCommentSubmit);
    subscribeBtn.addEventListener('click', showSubscribeOverlay);
    closeSubOverlay.addEventListener('click', onCloseSubOverlayClick);
    subscribeForm.addEventListener('submit', onSubscribeSubmit);
    contactForm.addEventListener('submit', onContactSubmit);
    //SIDEBARS
    headerDrawer.addEventListener('click', onSBLeftClick);
    sbRightDrawer.addEventListener('click', onSBRightClick);
    //THUMBS
    addThumbListeners();
    //TAPE PAGE LISTENERS
    addTapeListeners();
    addCommentListeners();
    addDownloadAndShareListeners();
    //REMOVE ZOOM ON MOBILE
    document.documentElement.addEventListener('touchstart', function (e) { }); //hover behaviour on touch devices
    const zoomEls = [searchResults, fixedContainer, ...overlays, audioPlayerWrapper];
    const zoomEvent = 'onwheel' in document ? 'wheel' : false;
    if (!zoomEvent)
        return;
    pages.forEach(page => page.addEventListener(zoomEvent, zoomHandler));
    zoomEls.forEach(el => el.addEventListener(zoomEvent, zoomHandler));
}
//ZOOM HANDLER
function zoomHandler(e) {
    e = e || window.event;
    const delta = -(e.deltaY ? e.deltaY : e.detail);
    if (delta % 1 !== 0) { //check if delta is a float (pinch), instead of int (wheel)
        e.preventDefault(); //prevent default pinch zoom
    }
}
//OVERLAYS
function onOverlayClick(e) {
    albumCaption.innerText = '';
    const target = e.target;
    if (e.currentTarget === refreshOverlay)
        return;
    if (target.classList.contains('overlay')) {
        closeOverlay(e.currentTarget);
    }
}
function onCloseSubOverlayClick() {
    closeOverlay(subscribeOverlay);
}
function closeOverlay(e) {
    rootDoc.classList.remove('no-scroll');
    e.classList.remove('overlay-change');
    setTimeout(() => {
        e.classList.add('no-display');
        if (e === subscribeOverlay)
            resetSubscribeForm();
    }, 400);
}
function showErrorOverlay() {
    rootDoc.classList.add('no-scroll');
    rootDoc.classList.add('no-pointers');
    errorOverlay.classList.remove('no-display');
    setTimeout(() => { errorOverlay.classList.add('overlay-change'); }, 10);
}
