import { contactEmail, contactForm, contactMsg, contactName, contactSubmitBtn } from "../global-var.js";
import { $, createElWithHTML } from "../global/global-dom.js";
import { fetchAPI } from "../global/global-fetch.js";
import { resetForm } from "../global/global-forms.js";
import { onRequest, onResponse } from "../global/global-handlers.js";
export function onContactSubmit(e) {
    e.preventDefault();
    onRequest(contactSubmitBtn);
    const name = contactName.value.trim();
    const email = contactEmail.value.trim().toLowerCase();
    const msg = contactMsg.value.trim();
    const payload = { name, email, msg };
    submitContactMsg(payload, contactSubmitBtn);
}
async function submitContactMsg(payload, btn) {
    const resp = await fetchAPI('/contact-form', 'POST', payload);
    onResponse(btn);
    appendContactResult(resp);
    if (resp && resp.success) {
        resetForm(contactForm);
    }
}
function appendContactResult(data) {
    const pResult = $(contactForm, '.contact-result')[0];
    if (pResult)
        pResult.remove();
    const classes = (data && data.success) ? 'contact-result' : 'contact-result error';
    const resultText = (data && data.success) ? 'Thank you, your message has been sent successfully' : 'An error has occured while submitting your form<br>Please try again';
    const p = createElWithHTML('p', classes, resultText);
    contactForm.append(p);
}
