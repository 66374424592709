import { $, _ } from "./global/global-dom.js";
// ---------------- STATIC GLOBAL DOM ELEMENTS
export let fixedContainer = _('fixedContainer'), header = _('header'), headerDrawer = _('headerDrawer'), sideBarRight = _('sideBarRight'), sbRightDrawer = _('sbRightDrawer'), sbRightTitle = _('sbRightTitle'), thumbsWrapper = _('thumbsWrapper');
//NAV
export const navLinks = $(document, '.a-links');
//GENRE DROPDOWN
export let genreDropDown = _('genreDropDown');
//OVERLAYS
export const overlays = $(document, '.overlay');
export let subscribeOverlay = _('subscribeOverlay'), albumOverlay = _('albumOverlay'), albumImgOverlay = _('albumImgOverlay'), albumCaption = _('albumCaption'), refreshOverlay = _('refreshOverlay'), reloadBtn = _('reloadBtn'), errorOverlay = _('errorOverlay');
//SPA PAGES
export const pages = $(document, '.page');
export let aboutContent = _('aboutContent'), tapeContent = _('tapeContent'), contactContent = _('contactContent'), privacyContent = _('privacyContent'), siteInfoContent = _('siteInfoContent');
//TAPE PAGE
export let tapeContentEdit = _('tapeContentEdit'), downloadZip = _('downloadZip'), shareFacebook = _('shareFacebook'), shareTwitter = _('shareTwitter'), shareEmail = _('shareEmail'), playAlbumBtn = _('playAlbumBtn');
//COMMENTS
export let commentsWrapper = _('commentsWrapper'), commentCount = _('commentCount'), commentForm = _('commentForm'), commentName = _('commentName'), commentMsg = _('commentMsg'), commentSubmitBtn = _('commentSubmitBtn');
//SEARCH ELEMENTS
export let searchResults = _('searchResults'), closeSearchOverlay = _('closeSearchOverlay'), searchResultsEdit = _('searchResultsEdit'), searchInput = _('searchInput'), searchValue = _('searchValue'), searchTitles = _('searchTitles'), closeSearch = _('closeSearch');
//CONTACT PAGE
export let contactForm = _('contactForm'), contactName = _('contactName'), contactEmail = _('contactEmail'), contactMsg = _('contactMsg'), contactSubmitBtn = _('contactSubmitBtn');
//SUBSCRIBE OVERLAY
export let subscribeBtn = _('subscribeBtn'), closeSubOverlay = _('closeSubOverlay'), subscribeOverlayEdit = _('subscribeOverlayEdit'), subscribeForm = _('subscribeForm'), subscribeName = _('subscribeName'), subscribeEmail = _('subscribeEmail'), subscribeSubmitBtn = _('subscribeSubmitBtn'), successSVG = _('successSVG'), errorSVG = _('errorSVG');
//AUDIO PLAYER
export let audioPlayerWrapper = _('audioPlayerWrapper'), playerMax = _('playerMax'), playerMin = _('playerMin'), expandBtn = _('expandPlayerBtnMin'), collapseBtn = _('minimizePlayerBtn'), progressBarMax = _('progressBar'), progressBarMin = _('progressBarMin'), volumeBarMax = _('volumeBar'), volumeBarMin = _('volumeBarMin'), volumeWrapper = _('volumeWrapper'), timeTextMin = _('progressDurationMin'), timeTextMax = _('progressDuration'), timeRemainder = _('progressRemainder'), playerTracksEdit = _('playerTracksEdit');
export const playBtns = $(audioPlayerWrapper, '.play-btn');
export const pauseBtns = $(audioPlayerWrapper, '.pause-btn');
export const prevBtns = $(audioPlayerWrapper, '.prev-btn');
export const forwardBtns = $(audioPlayerWrapper, '.forward-btn');
export const progressBars = $(audioPlayerWrapper, '.progress-bar');
export const progressWrappers = $(audioPlayerWrapper, '.progress-wrapper');
export const progresses = $(audioPlayerWrapper, '.progress');
export const volumeBtns = $(audioPlayerWrapper, '.volume-btn');
export const volumeRanges = $(audioPlayerWrapper, '.volume');
export const muteBtns = $(audioPlayerWrapper, '.mute-btn');
export const volumeBars = $(audioPlayerWrapper, '.volume-bar');
export const volumeWrappers = $(audioPlayerWrapper, '.volume-wrapper');
export const loadingSpinners = $(audioPlayerWrapper, '.player-loader');
export const plHeads = $(audioPlayerWrapper, '.play-head');
export const playerTitles = $(audioPlayerWrapper, '.player-title span');
export const playerMaxAlbum = $(audioPlayerWrapper, '.player-album span')[0];
export const playerMaxArtist = $(audioPlayerWrapper, '.player-artist span')[0];
export const smallPlayerThumb = $(audioPlayerWrapper, '.small-player-thumb')[0];
export const bigPlayerThumb = $(audioPlayerWrapper, '.big-player-thumb')[0];
// ------------- VARIABLE EXPORTS
export let CURRENT_PAGE = null, CURRENT_TAPE = null, IS_TOUCH_DEVICE, SEARCH_RESULT, TAPES_DB = [], APP_VERSION;
// ------------- GETTERS/SETTERS
export function initDBVariables() {
    const currentTape = Number(tapeContentEdit.getAttribute('data-id'));
    setCurrentTape(currentTape);
    const scriptDataEl = _('SERVER_DATA');
    if (!scriptDataEl)
        return;
    APP_VERSION = scriptDataEl.getAttribute('data-version');
    TAPES_DB = JSON.parse(scriptDataEl.textContent);
    if (!TAPES_DB || TAPES_DB.length === 0)
        return;
    return true;
}
export function setIsTouchDevice(val) {
    IS_TOUCH_DEVICE = val;
}
export function setCurrentTape(tapeID) {
    CURRENT_TAPE = tapeID;
}
export function setCurrentPage(page) {
    CURRENT_PAGE = page;
}
export function setSearchResults(results) {
    SEARCH_RESULT = results;
}
// ------------- TAPES DB
export function getTapeByUrl(url) {
    return TAPES_DB.find(tape => tape.t_url === url);
}
export function getTapeById(id) {
    return TAPES_DB.find(tape => tape.t_id === id);
}
