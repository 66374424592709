import { muteBtns, volumeBarMax, volumeBarMin, volumeBtns, volumeRanges } from "../global-var.js";
import { $ } from "../global/global-dom.js";
import { AUDIO_ELEMENT, IS_PLAYER_MAX, setVolumeGlobal, VOLUME_GLOBAL } from "./audio.js";
export function onMuteUnmute() {
    volumeBtns.forEach(btn => {
        if (btn.classList.contains('no-display')) {
            btn.classList.remove('no-display');
            muteBtns.forEach(m => m.classList.add('no-display'));
        }
        else {
            btn.classList.add('no-display');
            muteBtns.forEach(m => m.classList.remove('no-display'));
        }
    });
    if (AUDIO_ELEMENT.muted) {
        AUDIO_ELEMENT.muted = false;
        AUDIO_ELEMENT.volume = VOLUME_GLOBAL;
        volumeRanges.forEach(r => r.style.width = VOLUME_GLOBAL * 100 + '%');
    }
    else {
        AUDIO_ELEMENT.muted = true;
        volumeRanges.forEach(r => r.style.width = '0px');
    }
}
export function onVolumeDown(e) {
    e = e || window.event;
    const offsetX = e.offsetX;
    updateVolume(offsetX);
    document.addEventListener('mousemove', onVolumeMove);
    document.addEventListener('mouseup', onVolumeUp);
}
function onVolumeMove(e) {
    e = e || window.event;
    const el = (IS_PLAYER_MAX) ? volumeBarMax : volumeBarMin;
    const offsetX = e.clientX - el.getBoundingClientRect().x;
    updateVolume(offsetX);
}
function onVolumeUp() {
    document.removeEventListener('mousemove', onVolumeMove);
    document.removeEventListener('mouseup', onVolumeUp);
}
function updateVolume(offsetX) {
    const el = (IS_PLAYER_MAX) ? volumeBarMax : volumeBarMin;
    const value = Math.min(Math.max((offsetX / (el.offsetWidth || 1)), 0), 1);
    if (value > 0)
        AUDIO_ELEMENT.muted = false;
    else
        AUDIO_ELEMENT.muted = true;
    AUDIO_ELEMENT.volume = value;
    setVolumeGlobal(value);
    volumeRanges.forEach(r => r.style.width = value * 100 + '%');
    muteBtns.forEach(m => m.classList.add('no-display'));
    volumeBtns.forEach(v => {
        v.classList.remove('no-display');
        const waves = $(v, '.volume-waves');
        waves.forEach(wave => wave.style.visibility = 'visible');
        if (value <= .66 && value > .33) {
            waves[waves.length - 1].style.visibility = 'hidden';
        }
        else if (value <= .33 && value > 0) {
            waves[waves.length - 1].style.visibility = 'hidden';
            waves[waves.length - 2].style.visibility = 'hidden';
        }
        else if (value === 0) {
            waves.forEach(wave => wave.style.visibility = 'hidden');
            volumeBtns.forEach(a => a.classList.add('no-display'));
            muteBtns.forEach(m => m.classList.remove('no-display'));
        }
    });
}
