import { closeSearch, closeSearchOverlay, IS_TOUCH_DEVICE, searchInput, searchResults, searchResultsEdit, searchTitles, searchValue, SEARCH_RESULT, setSearchResults, TAPES_DB } from "../global-var.js";
import { filterRecordsByPropAndSearch, filterRecordsBySearch, mapObjProp, mapProp } from "../global/global-data-manip.js";
import { removeTags } from "../global/global-strings.js";
import { showSearchResults } from "./search-dom.js";
export function onSearchInput(e) {
    e = e || window.event;
    e.preventDefault();
    const target = e.currentTarget;
    const search = removeTags(target.value.toLowerCase().trim());
    if (search.length < 3) {
        onCloseSearch();
        return;
    }
    if (IS_TOUCH_DEVICE) {
        document.documentElement.scrollTop = 0;
    } //handle iphone scroll on input focus
    closeSearch.classList.remove('no-display');
    closeSearch.addEventListener('click', onCloseSearch);
    closeSearchOverlay.addEventListener('click', onCloseSearch);
    setSearchResults(getSearchResults(search));
    showSearchResults(SEARCH_RESULT, search);
    searchValue.innerText = removeTags(target.value.trim());
    ;
}
export function onCloseSearch(e) {
    if (e?.target) {
        searchInput.value = '';
    }
    setSearchResults({});
    closeSearch.removeEventListener('click', onCloseSearch);
    closeSearch.classList.add('no-display');
    closeSearchOverlay.removeEventListener('click', onCloseSearch);
    searchResults.classList.remove('search-results-change');
    searchResultsEdit.innerHTML = '';
    searchTitles.innerHTML = '';
    searchValue.innerText = '';
}
function getSearchResults(search) {
    const results = {
        t_artist: [],
        t_album: [],
        t_label: [],
        t_track: [],
        t_tag: []
    };
    const propList = ['t_artist', 't_album', 't_label'];
    const arrayList = ['sideA', 'sideB', 't_genre', 't_tag'];
    propList.forEach(p => {
        const filtered = filterRecordsByPropAndSearch(TAPES_DB, p, search);
        results[p] = Array.from(new Set(mapProp(filtered, p)));
    });
    let tracks = [];
    let tags = [];
    arrayList.forEach(p => {
        let result = [];
        const list = (p === 'sideA' || p === 'sideB') ? mapObjProp(TAPES_DB, 't_assets', p) : mapProp(TAPES_DB, p);
        list.forEach(row => {
            let match = (p === 'sideA' || p === 'sideB') ?
                mapProp(filterRecordsByPropAndSearch(row, 'title', search), 'title') :
                filterRecordsBySearch(row, search);
            if (match.length > 0) {
                match = Array.from(new Set(match));
            }
            result = Array.from(new Set(result.concat(match)));
        });
        if (p === 'sideA') {
            tracks = result;
        }
        if (p === 'sideB') {
            results.t_track = tracks.concat(result);
        }
        if (p === 't_genre') {
            tags = result;
        }
        if (p === 't_tag') {
            results[p] = tags.concat(result);
        }
    });
    return results;
}
