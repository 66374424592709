import { $, rootDoc } from "./global-dom.js";
//SERVER REQUEST/RESPONSE
export function onRequest(btn) {
    document.addEventListener('keydown', onPreventFormSubmit);
    requestLoading(true, btn);
    rootDoc.classList.add('no-pointers');
}
export function onResponse(btn) {
    document.removeEventListener('keydown', onPreventFormSubmit);
    requestLoading(false, btn);
    rootDoc.classList.remove('no-pointers');
}
//SPINNER
export function spinnerHandler(loading, spinner, button) {
    if (loading) {
        spinner.classList.remove("no-display");
        button.classList.add("no-display");
    }
    else {
        spinner.classList.add("no-display");
        button.classList.remove("no-display");
    }
}
export const requestLoading = (isLoading, btn) => {
    const spinner = $(btn, '.spinner')[0];
    const button = $(btn, '.btn-span')[0];
    if (isLoading) {
        btn.disabled = true;
        btn.classList.add('btn-disabled');
    }
    else {
        btn.disabled = false;
        btn.classList.remove('btn-disabled');
    }
    spinnerHandler(isLoading, spinner, button);
};
//PREVENT FORM SUBMIT
export function onPreventFormSubmit(e) {
    if (e.code === 'Enter') {
        e.preventDefault(); //avoid submitting form
    }
}
