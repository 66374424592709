import { errorSVG, subscribeEmail, subscribeForm, subscribeName, subscribeOverlay, subscribeOverlayEdit, subscribeSubmitBtn, successSVG } from "../global-var.js";
import { $, rootDoc } from "../global/global-dom.js";
import { fetchAPI } from "../global/global-fetch.js";
import { resetForm } from "../global/global-forms.js";
import { onRequest, onResponse } from "../global/global-handlers.js";
export function showSubscribeOverlay() {
    rootDoc.classList.add('no-scroll');
    subscribeOverlay.classList.remove('no-display');
    setTimeout(() => { subscribeOverlay.classList.add('overlay-change'); }, 10);
}
export function onSubscribeSubmit(e) {
    e.preventDefault();
    onRequest(subscribeSubmitBtn);
    const name = subscribeName.value.trim();
    const email = subscribeEmail.value.trim().toLowerCase();
    grecaptcha.ready(function () {
        grecaptcha.execute('6LepwKcZAAAAALHJ-g5QhATrueyfdUvqFFAkTJ2o', { action: 'subscribe' }).then(function (token) {
            const payload = { name, email, token };
            submitSubscriber(payload, subscribeSubmitBtn);
        });
    });
}
async function submitSubscriber(payload, btn) {
    const resp = await fetchAPI('/subscribe', 'POST', payload);
    onResponse(btn);
    if (resp && resp.error) {
        onSubmitError(resp.error);
        return;
    }
    onSubmitSuccess(resp.success);
}
function onSubmitSuccess(success) {
    subscribeOverlayEdit.classList.add('no-display');
    successSVG.classList.remove('no-display');
    const p = $(subscribeOverlay, '.subscribe-result')[0];
    p.classList.remove('no-display');
    p.innerText = (!success) ? 'YOU ARE ALREADY SUBSCRIBED' : 'THANK YOU FOR SUBSCRIBING TO MOROCCAN TAPES';
}
function onSubmitError(error) {
    subscribeOverlayEdit.classList.add('no-display');
    errorSVG.classList.remove('no-display');
    const p = $(subscribeOverlay, '.subscribe-result')[0];
    p.classList.remove('no-display');
    p.innerText = 'AN ERROR HAS OCCURED WHILE SUBMITTING YOUR FORM';
}
export function resetSubscribeForm() {
    resetForm(subscribeForm);
    subscribeOverlayEdit.classList.remove('no-display');
    successSVG.classList.add('no-display');
    errorSVG.classList.add('no-display');
    const p = $(subscribeOverlay, '.subscribe-result')[0];
    p.classList.add('no-display');
    p.innerText = '';
}
