//STRING MANIP
export function removeTags(str) {
    if ((str === null) || (str === '')) {
        return '';
    }
    else {
        str = str.toString();
    }
    return str.replace(/(<([^>]+)>)/ig, '');
}
export function findReplace(string, find, replace) {
    const regex = new RegExp(find, "g");
    return string.replace(regex, replace);
}
//DATE FORMATTING
export function formatDate(date) {
    let newDate = date.split('-');
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return `${months[Number(newDate[1]) - 1]}  ${Number(newDate[2])},  ${newDate[0]}`;
}
