import { getTapeById, getTapeByUrl, navLinks } from "../global-var.js";
import { executeTape } from "../tapes/tape-dom.js";
import { onALinks } from "./spa.js";
export function historyHandler(url, onInitialLoad) {
    const tape = getTapeByUrl(url);
    if (!tape)
        return;
    const state = { id: tape.t_id };
    const title = `${tape.t_artist} - ${tape.t_album}`;
    if (onInitialLoad)
        history.replaceState(state, title, `/${url}`);
    else
        history.pushState(state, title, `/${url}`);
    document.title = title;
}
export function staticHistoryHandler(url, onInitialLoad) {
    const state = { page: url };
    const title = titleSwitch(url);
    if (onInitialLoad) {
        history.replaceState(state, title, `/${url}`);
    }
    else {
        history.pushState(state, title, `/${url}`);
    }
    document.title = title;
}
export function onPopState(e) {
    e.preventDefault();
    const state = e.state;
    if (!state)
        return;
    if (state.id) {
        const aLink = navLinks.find((node) => node.getAttribute('data-page') === 'tapes');
        const ev = new Event('click');
        onALinks(ev, aLink, true);
        const tape = getTapeById(state.id);
        if (!tape)
            return;
        executeTape(tape, true);
    }
    else { //static pages
        const aLink = navLinks.find((node) => node.getAttribute('data-page') === state.page);
        const ev = new Event('click');
        onALinks(ev, aLink, true);
    }
}
function titleSwitch(url) {
    let title = '';
    switch (url) {
        case 'about':
            title = 'Moroccan Tapes | ABOUT';
            break;
        case 'contact':
            title = 'Moroccan Tapes | CONTACT';
            break;
        case 'privacy-policy':
            title = 'Moroccan Tapes | PRIVACY POLICY';
            break;
        case 'site-info':
            title = 'Moroccan Tapes | SITE INFO';
            break;
    }
    return title;
}
