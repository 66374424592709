import { handleAudioPlayer } from "../audio/audio-resize.js";
import { closeDrawer } from "../dom-states/side-bars.js";
import { CURRENT_PAGE, CURRENT_TAPE, getTapeById, navLinks, sideBarRight, thumbsWrapper } from "../global-var.js";
import { $ } from "../global/global-dom.js";
import { onALinks } from "../spa/spa.js";
import { executeTape } from "./tape-dom.js";
export function addThumbListeners() {
    const thumbs = $(thumbsWrapper, '.thumb');
    thumbs.forEach(thumb => thumb.addEventListener('click', onThumbClick));
}
export function onThumbClick() {
    const id = Number(this.getAttribute('data-id'));
    if (CURRENT_TAPE != id) {
        if (window.innerWidth <= 1080) {
            closeDrawer(sideBarRight, 'transform-sbright');
        }
        const tape = getTapeById(id);
        if (tape)
            executeTape(tape);
        ;
        handleAudioPlayer();
    }
    if (CURRENT_PAGE != 'tapes') {
        const aLink = navLinks.find((node) => node.getAttribute('data-page') === 'tapes');
        const ev = new Event('click');
        onALinks(ev, aLink);
    }
}
