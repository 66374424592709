import { audioPlayerWrapper, header, searchInput, searchResults, searchResultsEdit, searchTitles, SEARCH_RESULT, TAPES_DB } from "../global-var.js";
import { $, createEl, createElWithHTML, createElWithText, rootDoc } from "../global/global-dom.js";
import { showResults, updateSideBarResultText } from "./filter.js";
import { onCloseSearch } from "./search.js";
export function showSearchResults(result, search) {
    let count = 0;
    searchTitles.innerHTML = '';
    searchResultsEdit.innerHTML = '';
    for (let key in result) {
        if (result[key].length > 0) {
            const title = createElWithText('p', 'search-result-title', switchSearchProp(key), { 'data-key': key });
            searchTitles.append(title);
            if (count === 0) {
                title.classList.add('search-result-title-change');
                showSearchByTitle(result, search, key);
            }
            count++;
        }
    }
    if (count === 0) { //NO RESULTS
        searchTitles.innerHTML = '<p class="no-result">0 RESULTS</p>';
        adjustSearchHeight();
    }
    const titles = $(searchTitles, '.search-result-title');
    titles.forEach(title => title.addEventListener('click', onSearchTitleClick));
}
function switchSearchProp(prop) {
    let title = '';
    switch (prop) {
        case 't_artist':
            title = 'Artist';
            break;
        case 't_album':
            title = 'Album';
            break;
        case 't_label':
            title = 'Label';
            break;
        case 't_track':
            title = 'Track';
            break;
        case 't_tag':
            title = 'Tag';
    }
    return title;
}
function showSearchByTitle(result, search, key) {
    searchResultsEdit.innerHTML = '';
    const div = createEl('div', 'search-result-container');
    result[key].forEach(e => {
        const res = createElWithHTML('p', 'search-result-item', highlightSearchResult(e, search), { 'data-prop': key });
        div.append(res);
    });
    searchResultsEdit.append(div);
    addSearchListeners();
    header.scrollTop = 0;
    adjustSearchHeight();
}
function highlightSearchResult(match, query) {
    const startIndex = match.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(query);
    if (startIndex === -1)
        return match;
    const endIndex = startIndex + query.length;
    return match.substring(0, startIndex) + `<mark>${match.substring(startIndex, endIndex)}</mark>` + match.substring(endIndex, match.length);
}
function adjustSearchHeight() {
    const height = searchResultsEdit.scrollHeight + 129; //129 = SEARCH HEADER HEIGHT + SEARCH TITLES HEIGHT
    rootDoc.style.setProperty("--searchHeight", height + "px");
    searchResults.classList.add('search-results-change');
}
function onSearchTitleClick() {
    const search = searchInput.value.toLowerCase().trim();
    const key = this.getAttribute('data-key');
    const titles = $(searchTitles, '.search-result-title');
    titles.forEach(title => title.classList.remove('search-result-title-change'));
    this.classList.add('search-result-title-change');
    showSearchByTitle(SEARCH_RESULT, search, key);
}
function addSearchListeners() {
    const items = $(searchResultsEdit, '.search-result-item');
    items.forEach(item => item.addEventListener('click', onSearchResultClick));
}
function onSearchResultClick() {
    if (window.innerWidth <= 1080)
        onCloseSearch();
    else {
        searchResults.classList.remove('search-sb-change');
        audioPlayerWrapper.classList.remove('audio-player-change');
    }
    const search = this.innerText;
    const prop = this.getAttribute('data-prop');
    const results = (prop === 't_track') ?
        TAPES_DB.filter(tape => {
            const sideA = tape.t_assets.sideA.map(item => { return item.title; });
            const sideB = tape.t_assets.sideB.map(item => { return item.title; });
            const tracks = sideA.concat(sideB);
            if (tracks.includes(search))
                return tape;
        })
        : (prop === 't_tag') ?
            TAPES_DB.filter(tape => {
                const tags = tape.t_genre.concat(tape.t_tag);
                if (tags.includes(search))
                    return tape;
            })
            : TAPES_DB.filter(tape => tape[prop].includes(search));
    const title = 'SEARCH';
    showResults(results, title);
    updateSideBarResultText(search);
}
