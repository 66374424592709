import { onAudioResize } from "../audio/audio-resize.js";
import { audioPlayerWrapper, header, searchResults, sideBarRight } from "../global-var.js";
export function openDrawer(el, cl) {
    el.classList.remove(cl);
    const otherEl = (el === header) ? sideBarRight : header;
    const otherCl = (cl === 'transform-sbleft') ? 'transform-sbright' : 'transform-sbleft';
    if (window.innerWidth <= 1080 && !otherEl.classList.contains(otherCl)) {
        closeDrawer(otherEl, otherCl);
    }
}
export function closeDrawer(el, cl) {
    el.classList.add(cl);
}
export function onSBLeftClick() {
    if (header.classList.contains('transform-sbleft')) {
        openDrawer(header, 'transform-sbleft');
    }
    else {
        closeDrawer(header, 'transform-sbleft');
    }
}
export function onSBRightClick() {
    sideBarRight.addEventListener('transitionend', onSBTransition);
    audioPlayerWrapper.classList.remove('cubic-bezier');
    audioPlayerWrapper.classList.add('ease-out');
    if (sideBarRight.classList.contains('transform-sbright')) {
        openDrawer(sideBarRight, 'transform-sbright');
        audioPlayerWrapper.classList.remove('audio-player-change');
        searchResults.classList.remove('search-sb-change');
    }
    else {
        closeDrawer(sideBarRight, 'transform-sbright');
        if (window.innerWidth > 1080) {
            audioPlayerWrapper.classList.add('audio-player-change');
            searchResults.classList.add('search-sb-change');
        }
    }
}
function onSBTransition(e) {
    e = e || window.event;
    const currentTarget = e.currentTarget;
    if (e.propertyName === 'transform') {
        currentTarget.removeEventListener('transitionend', onSBTransition);
        audioPlayerWrapper.classList.remove('ease-out');
        audioPlayerWrapper.classList.add('cubic-bezier');
        onAudioResize();
    }
}
