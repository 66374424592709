import { handleAudioPlayer } from "../audio/audio-resize.js";
import { closeDrawer } from "../dom-states/side-bars.js";
import { aboutContent, contactContent, CURRENT_PAGE, header, headerDrawer, navLinks, pages, privacyContent, setCurrentPage, siteInfoContent, tapeContent, tapeContentEdit } from "../global-var.js";
import { $ } from "../global/global-dom.js";
import { historyHandler, staticHistoryHandler } from "./history.js";
export function onALinks(e, target, onPop) {
    e.preventDefault(); //PREVENT REQUESTING PAGE AND REFRESHING
    const currentTarget = (target) ? target : e.currentTarget;
    const page = currentTarget.getAttribute('data-page');
    if (CURRENT_PAGE != page) {
        navLinks.forEach(a => a.classList.remove('nav-current'));
        currentTarget.classList.add('nav-current');
        pages.forEach(p => p.classList.add('no-display'));
        switchPage(page, onPop);
        if (window.innerWidth <= 1080) {
            closeDrawer(header, 'transform-sbleft');
        }
        document.documentElement.scrollTop = 0;
        setCurrentPage(page);
        handleAudioPlayer();
    }
}
function switchPage(page, onPop) {
    switch (page) {
        case 'about':
            aboutContent.classList.remove('no-display');
            $(headerDrawer, 'span')[0].innerText = 'ABOUT';
            if (!onPop) {
                staticHistoryHandler(page, false);
            }
            break;
        case 'tapes':
            tapeContent.classList.remove('no-display');
            $(headerDrawer, 'span')[0].innerText = 'TAPES';
            if (!onPop) {
                const url = tapeContentEdit.getAttribute('data-url');
                historyHandler(url, false);
            }
            break;
        case 'contact':
            contactContent.classList.remove('no-display');
            $(headerDrawer, 'span')[0].innerText = 'CONTACT';
            if (!onPop) {
                staticHistoryHandler(page, false);
            }
            break;
        case 'privacy-policy':
            privacyContent.classList.remove('no-display');
            $(headerDrawer, 'span')[0].innerText = 'PRIVACY POLICY';
            if (!onPop) {
                staticHistoryHandler(page, false);
            }
            break;
        case 'site-info':
            siteInfoContent.classList.remove('no-display');
            $(headerDrawer, 'span')[0].innerText = 'SITE INFO';
            if (!onPop) {
                staticHistoryHandler(page, false);
            }
    }
}
