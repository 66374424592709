//SELECTORS
export const rootDoc = document.querySelector(":root");
export function _(id) {
    return document.getElementById(id);
}
export function $(parent, selector) {
    return Array.from(parent.querySelectorAll(selector));
}
export function createEl(tag, classes, attr) {
    const el = document.createElement(tag);
    el.setAttribute('class', classes);
    if (attr) {
        for (let key in attr) {
            el.setAttribute(key, attr[key]);
        }
    }
    return el;
}
export function createElWithText(tag, classes, text, attr) {
    const el = createEl(tag, classes);
    if (attr) {
        for (let key in attr) {
            el.setAttribute(key, attr[key]);
        }
    }
    el.innerText = text;
    return el;
}
export function createElWithHTML(tag, classes, html, attr) {
    const el = createEl(tag, classes);
    if (attr) {
        for (let key in attr) {
            el.setAttribute(key, attr[key]);
        }
    }
    el.innerHTML = html;
    return el;
}
export function createImageEl(classes, attr) {
    const img = createEl('img', classes);
    for (let key in attr) {
        img.setAttribute(key, attr[key]);
    }
    return img;
}
export function createAudioEl(classes, attr) {
    const audio = createEl('audio', classes);
    for (let key in attr) {
        audio.setAttribute(key, attr[key]);
    }
    return audio;
}
export function createVideoEl(classes, attr) {
    const video = createEl('video', classes);
    for (let key in attr) {
        video.setAttribute(key, attr[key]);
    }
    return video;
}
export function createBtn(classes, attr) {
    const btn = createElWithText('button', classes, attr.text);
    for (let key in attr) {
        btn.setAttribute(key, attr[key]);
    }
    return btn;
}
export function createBtnWithSpinner(classes, attr) {
    const btn = createEl('button', classes);
    for (let key in attr) {
        btn.setAttribute(key, attr[key]);
    }
    const spinner = createEl('div', 'spinner no-display');
    const span = createElWithText('span', 'btn-span', attr.text);
    btn.append(spinner);
    btn.append(span);
    return btn;
}
export function createFragment(fragments) {
    const fragment = document.createDocumentFragment();
    fragments.forEach(fr => fragment.append(fr));
    return fragment;
}
//DOM APPENDS
export function appendEl(tag, classes, target, attr) {
    const el = createEl(tag, classes, attr);
    target.append(el);
}
export function appendElWithText(tag, classes, target, text, attr) {
    const el = createElWithText(tag, classes, text, attr);
    target.append(el);
}
export function appendElWithHTML(tag, classes, target, html, attr) {
    const el = createElWithHTML(tag, classes, html, attr);
    target.append(el);
}
//CUSTOM APPENDS
//LOADERS
export function createCommentsLoader() {
    const wrapper = createEl('div', 'comments-loader lds-roller-wrapper flex-container flex-center');
    const loader = createEl('div', 'lds-roller');
    for (let i = 0; i < 8; i++) {
        loader.append(createEl('div', ''));
    }
    wrapper.append(loader);
    return wrapper;
}
