import { onTrackClick } from "../audio/audio.js";
import { albumCaption, albumImgOverlay, albumOverlay, downloadZip, shareEmail, shareFacebook, shareTwitter, tapeContentEdit } from "../global-var.js";
import { $, createEl, rootDoc, _ } from "../global/global-dom.js";
import { findReplace } from "../global/global-strings.js";
import { onLabelSearch, onTagSearch } from "../search/filter.js";
export function addTapeListeners() {
    const tracks = $(tapeContentEdit, '.tracks');
    tracks.forEach(tr => tr.addEventListener('click', onTrackClick));
    const albumCover = $(tapeContentEdit, '.album-cover')[0];
    albumCover.addEventListener('click', onAlbumOverlay);
    const label = $(tapeContentEdit, '.label')[0];
    label.addEventListener('click', onLabelSearch);
    const tags = $(tapeContentEdit, '.tags');
    tags.forEach(t => t.addEventListener('click', onTagSearch));
    _('playAlbumBtn').addEventListener('click', onTrackClick);
}
export function addDownloadAndShareListeners() {
    downloadZip.addEventListener('click', onDownloadZip);
    shareFacebook.addEventListener('click', onShareFacebook);
    shareTwitter.addEventListener('click', onShareTwitter);
    shareEmail.addEventListener('click', onShareEmail);
}
export function onDownloadZip() {
    const url = this.getAttribute('data-url');
    const a = createEl('a', '', { href: url, download: '' });
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
export function onShareFacebook() {
    const url = this.getAttribute('data-url');
    window.open(`https://www.facebook.com/sharer.php?u=https%3A%2F%2Fmoroccantapes.com%2F${url}`, "popup", "width=600,height=600");
}
export function onShareTwitter() {
    const url = this.getAttribute('data-url');
    window.open(`http://www.twitter.com/intent/tweet?url=https://moroccantapes.com%2F${url}`, "popup", "width=600,height=600");
}
export function onShareEmail() {
    const url = this.getAttribute('data-url');
    const artist = findReplace(this.getAttribute('data-artist'), '&', 'and');
    const album = this.getAttribute('data-album');
    const a = createEl('a', '', { href: `mailto:?subject=${artist} - ${album}&body=Check this tape out - https://moroccantapes.com/${url}` });
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
//ALBUM COVER OVERLAY
function onAlbumOverlay() {
    const width = Math.min(window.innerWidth - 60, window.innerHeight - 150);
    albumImgOverlay.setAttribute('src', this.getAttribute('src'));
    albumImgOverlay.setAttribute('alt', this.getAttribute('alt'));
    rootDoc.style.setProperty("--imgWidth", width + "px");
    rootDoc.classList.add('no-scroll');
    albumOverlay.classList.remove('no-display');
    setTimeout(() => { albumOverlay.classList.add('overlay-change'); }, 10);
    setTimeout(() => { albumCaption.innerText = this.getAttribute('alt'); }, 100);
}
