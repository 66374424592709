import { IS_TOUCH_DEVICE, plHeads, progressBarMax, progressBarMin, progresses, progressWrappers, timeRemainder, timeTextMax, timeTextMin } from "../global-var.js";
import { $ } from "../global/global-dom.js";
import { onNext } from "./audio-listeners.js";
import { AUDIO_ELEMENT, IS_AUDIO_ON, IS_PLAYER_MAX } from "./audio.js";
export function onThumbDown(e) {
    e = e || window.event;
    AUDIO_ELEMENT.pause();
    const el = (IS_PLAYER_MAX) ? progressBarMax : $(document, '.player-middle')[0];
    const padding = (IS_PLAYER_MAX) ? 0 : 15;
    const layerX = (IS_TOUCH_DEVICE) ? e.targetTouches[0].clientX : e.clientX;
    const offsetX = layerX - el.getBoundingClientRect().x - padding;
    updateThumbPos(offsetX);
    if (IS_TOUCH_DEVICE) {
        plHeads.forEach(p => p.classList.add('play-head-touch-change'));
        progressWrappers.forEach(wrapper => {
            wrapper.addEventListener('touchmove', onThumbMove);
            wrapper.addEventListener('touchend', onThumbUp);
        });
    }
    else {
        document.addEventListener('mousemove', onThumbMove);
        document.addEventListener('mouseup', onThumbUp);
    }
    AUDIO_ELEMENT.removeEventListener('ended', onNext);
}
function updateThumbPos(offsetX) {
    const progressBar = (IS_PLAYER_MAX) ? progressBarMax : progressBarMin;
    const perc = Math.max(Math.min((offsetX / progressBar.offsetWidth), 1) * 100, 0);
    updateTime(perc);
}
function updateTime(perc) {
    const duration = (isNaN(AUDIO_ELEMENT.duration)) ? 0 : AUDIO_ELEMENT.duration;
    AUDIO_ELEMENT.currentTime = (perc * duration) / 100;
    timeUpdate();
}
function onThumbMove(e) {
    e = e || window.event;
    const el = (IS_PLAYER_MAX) ? progressBarMax : $(document, '.player-middle')[0];
    const padding = (IS_PLAYER_MAX) ? 0 : 15;
    const layerX = (IS_TOUCH_DEVICE) ? e.targetTouches[0].clientX : e.clientX;
    const offsetX = layerX - el.getBoundingClientRect().x - padding;
    updateThumbPos(offsetX);
}
function onThumbUp(e) {
    e = e || window.event;
    const el = (IS_PLAYER_MAX) ? progressBarMax : $(document, '.player-middle')[0];
    const padding = (IS_PLAYER_MAX) ? 0 : 15;
    const layerX = (IS_TOUCH_DEVICE) ? e.changedTouches[0].clientX : e.clientX;
    const offsetX = layerX - el.getBoundingClientRect().x - padding;
    AUDIO_ELEMENT.addEventListener('ended', onNext);
    updateThumbPos(offsetX);
    if (IS_AUDIO_ON) {
        if (progresses[0].style.width === '100%') {
            onNext();
        }
        else {
            AUDIO_ELEMENT.play();
        }
    }
    if (IS_TOUCH_DEVICE) {
        plHeads.forEach(p => p.classList.remove('play-head-touch-change'));
        progressWrappers.forEach(wrapper => {
            wrapper.removeEventListener('touchmove', onThumbMove);
            wrapper.removeEventListener('touchend', onThumbUp);
        });
    }
    else {
        document.removeEventListener('mousemove', onThumbMove);
        document.removeEventListener('mouseup', onThumbUp);
    }
}
export function timeUpdate() {
    const perc = (AUDIO_ELEMENT.currentTime / AUDIO_ELEMENT.duration) * 100;
    progresses.forEach(p => p.style.width = `${perc}%`);
    plHeads.forEach(p => p.style.left = `${perc}%`);
    const duration = (isNaN(AUDIO_ELEMENT.duration)) ? '0:00' : formatAudioTime(AUDIO_ELEMENT.duration);
    timeTextMin.innerHTML = `${formatAudioTime(AUDIO_ELEMENT.currentTime)} / ${duration}`;
    timeTextMax.innerHTML = `${formatAudioTime(AUDIO_ELEMENT.currentTime)}`;
    timeRemainder.innerHTML = (isNaN(AUDIO_ELEMENT.duration)) ? '0:00' : `-${formatAudioTime(AUDIO_ELEMENT.duration - AUDIO_ELEMENT.currentTime)}`;
}
function formatAudioTime(duration) {
    let totalMin = Math.floor((duration) / 60);
    let totalSec = Math.floor((duration) - (totalMin * 60));
    totalMin = (totalMin < 10) ? `${totalMin}` : totalMin;
    totalSec = (totalSec < 10) ? `0${totalSec}` : totalSec;
    return `${totalMin}:${totalSec}`;
}
