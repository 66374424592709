import { collapseBtn, expandBtn, forwardBtns, IS_TOUCH_DEVICE, loadingSpinners, muteBtns, pauseBtns, playBtns, prevBtns, progressBars, progressWrappers, volumeBars, volumeBtns, volumeWrapper, volumeWrappers } from "../global-var.js";
import { onPlayerExpand, updatePlayerLoader, updateTitles } from "./audio-dom.js";
import { onAudioResize } from "./audio-resize.js";
import { AUDIO_ELEMENT, CURRENT_SONG, IS_AUDIO_ON, IS_LOADING, setCurrentSong, setIsAudioOn, setIsLoading, SRC_LIST, TRACKS_LENGTH } from "./audio.js";
import { onThumbDown, timeUpdate } from "./scroll-handlers.js";
import { onMuteUnmute, onVolumeDown } from "./volume-handlers.js";
export function addAudioListeners() {
    AUDIO_ELEMENT.addEventListener('timeupdate', timeUpdate);
    AUDIO_ELEMENT.addEventListener('loadeddata', timeUpdate);
    AUDIO_ELEMENT.addEventListener('ended', onNext);
    AUDIO_ELEMENT.addEventListener('loadstart', onLoadStart);
    AUDIO_ELEMENT.addEventListener('loadedmetadata', onLoadEnd);
    playBtns.forEach(btn => btn.addEventListener('click', onPlayPause));
    pauseBtns.forEach(btn => btn.addEventListener('click', onPlayPause));
    prevBtns.forEach(btn => btn.addEventListener('click', onPrev));
    forwardBtns.forEach(btn => btn.addEventListener('click', onNext));
    volumeBtns.forEach(btn => btn.addEventListener('click', onMuteUnmute));
    muteBtns.forEach(btn => btn.addEventListener('click', onMuteUnmute));
    if (IS_TOUCH_DEVICE) {
        progressWrappers.forEach(btn => btn.addEventListener('touchstart', onThumbDown));
        volumeWrapper.classList.add('no-display');
        volumeWrappers.forEach(v => v.classList.add('no-display'));
    }
    else {
        progressBars.forEach(btn => btn.addEventListener('mousedown', onThumbDown));
        volumeBars.forEach(btn => btn.addEventListener('mousedown', onVolumeDown));
    }
    expandBtn.addEventListener('click', onPlayerExpand);
    collapseBtn.addEventListener('click', onPlayerExpand);
    document.addEventListener('keydown', onSpaceBar);
    window.addEventListener('resize', onAudioResize);
}
//ON LOAD START/END
function onLoadStart() {
    setIsLoading(true);
    const selector = (!IS_AUDIO_ON) ? playBtns : pauseBtns;
    loadingSpinners.forEach(btn => btn.classList.remove('no-display'));
    selector.forEach(btn => btn.classList.add('no-display'));
}
function onLoadEnd() {
    const selector = (!IS_AUDIO_ON) ? playBtns : pauseBtns;
    loadingSpinners.forEach(btn => btn.classList.add('no-display'));
    selector.forEach(btn => btn.classList.remove('no-display'));
    setIsLoading(false);
}
//TRANSPORT CONTROLS
function onSpaceBar(e) {
    const target = e.target;
    if (target.nodeName != 'INPUT' && target.nodeName != 'TEXTAREA') {
        if (e.code === 'Space' || e.keyCode === 32) {
            e.preventDefault(); //avoid automatic scrolling page down
            if (!IS_LOADING)
                onPlayPause();
        }
    }
}
function onPlayPause() {
    if (AUDIO_ELEMENT.paused) {
        onPlay();
        updatePlayerLoader(false);
    }
    else {
        onPause();
        updatePlayerLoader(true);
    }
}
export function onPlay() {
    AUDIO_ELEMENT.play();
    playBtns.forEach(btn => btn.classList.add('no-display'));
    pauseBtns.forEach(btn => btn.classList.remove('no-display'));
    setIsAudioOn(true);
}
export function onPause() {
    AUDIO_ELEMENT.pause();
    playBtns.forEach(btn => btn.classList.remove('no-display'));
    pauseBtns.forEach(btn => btn.classList.add('no-display'));
    setIsAudioOn(false);
}
export function onNext() {
    if (CURRENT_SONG === TRACKS_LENGTH - 1) {
        onPause();
        updatePlayerLoader(true);
        AUDIO_ELEMENT.currentTime = 0;
    }
    else {
        AUDIO_ELEMENT.pause();
        setCurrentSong(CURRENT_SONG + 1);
        AUDIO_ELEMENT.src = SRC_LIST[CURRENT_SONG];
        updateTitles();
        if (IS_AUDIO_ON) {
            AUDIO_ELEMENT.play();
        }
    }
    onAudioResize();
}
function onPrev() {
    if (CURRENT_SONG === 0) {
        onPause();
        updatePlayerLoader(true);
        AUDIO_ELEMENT.currentTime = 0;
    }
    else {
        AUDIO_ELEMENT.pause();
        setCurrentSong(CURRENT_SONG - 1);
        AUDIO_ELEMENT.src = SRC_LIST[CURRENT_SONG];
        updateTitles();
        if (IS_AUDIO_ON) {
            AUDIO_ELEMENT.play();
        }
    }
    onAudioResize();
}
