import { navLinks, TAPES_DB } from "../global-var.js";
import { findReplace } from "../global/global-strings.js";
import { historyHandler, staticHistoryHandler } from "./history.js";
import { onALinks } from "./spa.js";
export function routeHandler() {
    const url = findReplace(window.location.pathname, '/', '');
    if (url.length > 1) {
        const staticOn = switchUrl(url);
        if (!staticOn) { //tapes pages
            historyHandler(url, true);
            const aLink = navLinks.find((node) => node.getAttribute('data-page') === 'tapes');
            const ev = new Event('click');
            onALinks(ev, aLink, true);
        }
        else { //static pages
            staticHistoryHandler(url, true);
            const aLink = navLinks.find((node) => node.getAttribute('data-page') === url);
            const ev = new Event('click');
            onALinks(ev, aLink, true);
        }
    }
    else { //initial load
        const tapeUrl = TAPES_DB[0].t_url;
        historyHandler(tapeUrl, true);
        const aLink = navLinks.find((node) => node.getAttribute('data-page') === 'tapes');
        const ev = new Event('click');
        onALinks(ev, aLink, true);
    }
}
function switchUrl(url) {
    let staticOn = true;
    switch (url) {
        case 'about':
        case 'contact':
        case 'privacy-policy':
        case 'site-info':
            break;
        default:
            staticOn = false;
    }
    return staticOn;
}
