export async function fetchAPI(url, method, payload) {
    const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };
    try {
        const response = await fetch(url, options);
        return await response.json();
    }
    catch (err) {
        console.error(err);
        return { error: 'An unexpected error occured<br><br>Please check your internet connection' };
    }
}
