import { audioPlayerWrapper, bigPlayerThumb, playerMax, playerMaxAlbum, playerMaxArtist, playerMin, playerTitles, playerTracksEdit, smallPlayerThumb } from "../global-var.js";
import { $, createEl, createElWithHTML, createFragment, rootDoc } from "../global/global-dom.js";
import { onPlay } from "./audio-listeners.js";
import { onAudioResize } from "./audio-resize.js";
import { ALBUM_TITLE, ARTIST_TITLE, AUDIO_ELEMENT, CURRENT_SONG, DUR_LIST, IS_AUDIO_ON, setCurrentSong, setIsPlayerMax, SRC_LIST, TRACK_LIST } from "./audio.js";
//DOM UPDATES
export function updateTitles() {
    playerTitles.forEach(title => title.innerText = `${ARTIST_TITLE} | ${TRACK_LIST[CURRENT_SONG]}`);
    updatePlayerLoader(false);
}
export function updateAlbumTitles() {
    playerMaxAlbum.innerText = ALBUM_TITLE;
    playerMaxArtist.innerText = ARTIST_TITLE;
}
export function updateFullTrackListView() {
    const fragments = [];
    TRACK_LIST.forEach((row, index) => {
        const div = createEl('div', 'player-tracks flex-container', { 'data-track': index });
        const track = createElWithHTML('p', 'player-track marquee mask-gradient-5-perc', `<span>${row}</span>`);
        const dur = createElWithHTML('p', 'player-dur', DUR_LIST[index]);
        div.append(track, dur);
        fragments.push(div);
    });
    const fragment = createFragment(fragments);
    playerTracksEdit.innerHTML = '';
    playerTracksEdit.append(fragment);
    const tracks = $(playerTracksEdit, '.player-tracks');
    tracks.forEach(tr => tr.addEventListener('click', onPlayerMaxTrackClick));
}
export function updateAudioThumbs(src) {
    smallPlayerThumb.setAttribute('style', `background-image:url(${src});`);
    bigPlayerThumb.setAttribute('style', `background-image:url(${src});`);
}
export function updatePlayerLoader(onAudioOff) {
    const tracks = $(audioPlayerWrapper, '.player-tracks');
    tracks.forEach(tr => tr.classList.remove('player-tracks-active'));
    const playing = $(audioPlayerWrapper, '.playing')[0];
    if (playing)
        playing.remove();
    if (!onAudioOff) {
        const current = tracks.find(node => Number(node.getAttribute('data-track')) === CURRENT_SONG);
        if (!current)
            return;
        current.classList.add('player-tracks-active');
        if (IS_AUDIO_ON) {
            const track = $(current, '.player-track')[0];
            track.after(createPlayingLoader());
        }
    }
}
function createPlayingLoader() {
    const div = createEl('div', 'playing flex-container');
    for (let i = 1; i < 4; i++) {
        const child = createEl('span', `playing-bar playing-bar${i}`);
        div.append(child);
    }
    return div;
}
function onPlayerMaxTrackClick() {
    setCurrentSong(Number(this.getAttribute('data-track')));
    AUDIO_ELEMENT.src = SRC_LIST[CURRENT_SONG];
    onPlay();
    updateTitles();
    onAudioResize();
}
//TOGGLE PLAYER MIN/MAX
function onPlayerMax() {
    audioPlayerWrapper.classList.add('audio-player-max');
    playerMax.classList.remove('no-display');
    playerMin.classList.add('no-display');
    rootDoc.classList.add('no-scroll');
    rootDoc.classList.add('no-select');
    setIsPlayerMax(true);
    onAudioResize();
}
export function onPlayerMin() {
    audioPlayerWrapper.addEventListener('transitionend', onPlayerMinTransition);
    audioPlayerWrapper.classList.remove('audio-player-max');
}
function onPlayerMinTransition(e) {
    e = e || window.event;
    const target = e.currentTarget;
    if (e.propertyName === 'height') {
        target.removeEventListener('transitionend', onPlayerMinTransition);
        if (window.innerWidth <= 1080) {
            audioPlayerWrapper.classList.remove('audio-player-margin-change');
        }
        playerMax.classList.add('no-display');
        playerMin.classList.remove('no-display');
        rootDoc.classList.remove('no-scroll');
        rootDoc.classList.remove('no-select');
        setIsPlayerMax(false);
        onAudioResize();
    }
}
export function onPlayerExpand() {
    if (playerMax.classList.contains('no-display')) {
        if (window.innerWidth > 1080) {
            onPlayerMax();
        }
        else {
            audioPlayerWrapper.classList.remove('cubic-bezier');
            audioPlayerWrapper.classList.add('audio-player-margin-change');
            setTimeout(() => {
                audioPlayerWrapper.classList.add('cubic-bezier');
                onPlayerMax();
            }, 100);
        }
    }
    else {
        onPlayerMin();
    }
}
