import { openDrawer } from "../dom-states/side-bars.js";
import { sbRightDrawer, sbRightTitle, sideBarRight, TAPES_DB } from "../global-var.js";
import { sortByDescendingNumber, sortByProp } from "../global/global-data-manip.js";
import { createElWithHTML } from "../global/global-dom.js";
import { updateThumbs } from "../tapes/thumbs-dom.js";
export function onDropDown() {
    onTagDropDown(this.value);
}
export function onTagDropDown(search) {
    if (!search || search === '')
        return;
    const results = (search === 'A-Z') ?
        sortByProp(TAPES_DB, 't_artist') :
        (search === 'All') ?
            sortByDescendingNumber(TAPES_DB, 't_id') :
            sortByProp(TAPES_DB.filter(tape => tape.t_genre.includes(search)), 't_artist');
    const title = (search === 'A-Z') ? 'A-Z' : (search === 'All') ? 'ALL TAPES' : search.toUpperCase();
    showResults(results, title);
}
export function onLabelSearch() {
    const search = this.getAttribute('data-tag');
    const results = TAPES_DB.filter(tape => tape.t_label.toLowerCase() === search.toLowerCase());
    const title = 'LABEL';
    showResults(results, title);
    updateSideBarResultText(search);
}
export function onTagSearch() {
    const search = this.getAttribute('data-tag');
    const results = TAPES_DB.filter(tape => tape.t_genre.includes(search) || tape.t_tag.includes(search));
    const title = search.toUpperCase();
    showResults(results, title);
}
//SHOW FILTER/SEARCH RESULTS
export function showResults(results, title) {
    sideBarRight.scrollTop = 0;
    updateThumbs(results);
    sbRightDrawer.innerHTML = `CATALOG | <span>${title}</span>`;
    sbRightTitle.innerHTML = `${title}<span>(${results.length})</span>`;
    openDrawer(sideBarRight, 'transform-sbright');
}
export function updateSideBarResultText(search) {
    const p = createElWithHTML('p', '', `Results for "${search}"`);
    sbRightTitle.append(p);
}
